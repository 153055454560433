import { useAuth0 } from '@auth0/auth0-react';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../auth/authHelpers';
import { PDFJS_SCRIPT_SRC_LIST } from '../../constants/site';
import {
    COLORS,
    SIZES,
} from '../../constants/theme';
import { customerDataMicroserviceRequest } from '../../services/api/customer-data';
import {
    getDateTimeStr,
} from '../../utils/date';
import { loadScriptsSequentially } from '../../utils/loadResources';

import Button from '../shared/Button';
import { FileUploadContainer } from '../shared/DropNUpload/FileUploadContainer';

export const ADD = 'ADD';
export const EDIT = 'EDIT';
const PUB_DATE_LABEL = `This is the date that is displayed under publication thumbnail
If left blank it will display the date when it is published.`;
const PUB_TITLE_LABEL = `Title will display under the publication thumbnail. You can enter in a name.
If left bank, it will default to the name of the file.`;

const userPublicationBaseUrl = process.env.USER_PUBLICATION_API_URL;

function calculateScale(width, height, viewport) {
    if (width > 0) {
        return width / viewport.width;
    }
    if (height > 0) {
        return height / viewport.height;
    }
    return 1;
}

export const PublicationAddEdit = ({
    salesforceId,
    publication,
    actionType,
    setIsDrawerOpen,
    loadPublications,
    setIsAddEditView,
    setManagePublicationsTitle,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [publicationDateLabel, setPublicationLabelDate] = useState(new Date(publication?.publishDate || new Date()));
    const [userDisplayDate, setUserDisplayDate] = useState(new Date(publication?.displayDate || new Date()));
    const [viewSchdPubDate, setViewSchdPubDate] = useState(actionType === EDIT);
    const [publicationTitle, setPublicationTitle] = useState(publication?.title || '');
    const [publicationFileName, setPublicationFileName] = useState('');
    const [pdfFile, setPdfFile] = useState();
    const [uploading, setUploading] = useState(false);
    const [smallThumbnailFile, setSmallThumbnailFile] = useState();
    const [largeThumbnailFile, setLargeThumbnailFile] = useState();
    const [largeThumbnailUrl, setLargeThumbnailUrl] = useState(publication?.largeThumbnailUrl);
    const [highResolutionThumbnailFile, setHighResolutionThumbnailFile] = useState();
    const [saving, setSaving] = useState(false);
    const [xhr] = useState(new XMLHttpRequest());
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileError, setFileError] = useState(null);

    const publicationId = publication?.publicationId;

    useEffect(() => {
        // Load dflip JS files sequentially
        loadScriptsSequentially(PDFJS_SCRIPT_SRC_LIST);
    }, []);

    const handleAddEditCancel = () => {
        xhr.abort();
        setSaving(false);
        setUploadProgress(0);
        setIsDrawerOpen(false);
        setIsAddEditView(false);
        setManagePublicationsTitle('Manage Publications');
    };

    const publicationDateInputField = (id) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                value={publicationDateLabel}
                onChange={(value) => setPublicationLabelDate(value)}
                slotProps={{
                    textField: {
                        id,
                    },
                }}
                disabled={isHidden}
            />
        </LocalizationProvider>
    );

    const userDisplayDateInputField = (id) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                value={userDisplayDate}
                onChange={(value) => setUserDisplayDate(value)}
                // disablePast
                minDate={new Date(publicationDateLabel)}
                slotProps={{
                    textField: {
                        id,
                    },
                }}
                disabled={isHidden}
            />
        </LocalizationProvider>
    );

    const getFileNameWithoutExtension = (file) => {
        const fullName = file.name;
        return fullName.substr(0, fullName.lastIndexOf('.')) || fullName;
    };

    const canvasToBlob = (canvas) => new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error('Failed to convert canvas to blob'));
            }
        });
    });

    const blobToFile = (blob, fileName) => new File([blob], fileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
    });

    const generateThumbnail = async (file, width, height) => {
        // Load the PDF
        const pdf = await window.pdfjsLib.getDocument(URL.createObjectURL(file)).promise;

        // Fetch the first page
        const page = await pdf.getPage(1);

        // Define a viewport with a desired width
        const viewport = page.getViewport({ scale: 1.0 });
        const scale = calculateScale(width, height, viewport);
        const thumbViewport = page.getViewport({ scale });

        // Prepare a canvas and render the page onto it
        const canvas = document.createElement('canvas');
        canvas.width = thumbViewport.width;
        canvas.height = thumbViewport.height;
        const context = canvas.getContext('2d');
        const renderContext = {
            canvasContext: context,
            viewport: thumbViewport,
        };
        await page.render(renderContext).promise;

        const blob = await canvasToBlob(canvas);
        return {
            blob,
            url: canvas.toDataURL(),
        };
    };

    const handleFileChanged = async (file) => {
        if (!window.pdfjsLib) {
            toast.error('Please wait a moment for necessary resources to load.');
            return;
        }

        if (file) {
            const today = new Date();
            const fileNameWOExt = getFileNameWithoutExtension(file);
            const jpgFilename = `${today.getTime()}-${fileNameWOExt}.jpg`;
            const pdfFilename = `${today.getTime()}-${fileNameWOExt}.pdf`;

            const { blob: smallTnBlob } = await generateThumbnail(file, 0, 132);
            const { blob: lgTnBlob, url: lgTnUrl } = await generateThumbnail(file, 0, 300);
            const { blob: highResolutionTnBlob } = await generateThumbnail(file, 0, 924);

            // Check for spaces in file name, and exit function if present
            const fileNameRegex = /[^A-Za-z0-9._-\s]/;
            if (fileNameRegex.test(file.name)) {
                setFileError('Filenames can only contain the following characters: alpha, numeric, dash (-), period (.), underscore (_), and spaces.');

                return;
            }

            setFileError(null);

            setPdfFile(file);
            setPublicationTitle(file.name);
            setPublicationFileName(pdfFilename);
            setSmallThumbnailFile(blobToFile(smallTnBlob, `tn_${jpgFilename}`));
            setLargeThumbnailFile(blobToFile(lgTnBlob, `lgtn_${jpgFilename}`));
            setHighResolutionThumbnailFile(blobToFile(highResolutionTnBlob, `htn_${jpgFilename}`));
            setLargeThumbnailUrl(lgTnUrl);
        }
    };

    const handleClearFile = () => {
        setFileError(null);
        setPdfFile(undefined);
        setSmallThumbnailFile(undefined);
        setLargeThumbnailFile(undefined);
        setHighResolutionThumbnailFile(undefined);
        setLargeThumbnailUrl(undefined);
        setPublicationFileName('');
    };

    const getSignedUrl = async (token, filename, type) => {
        const signUrl = `/organizations/${salesforceId}/user-publication/signed-url`;

        const response = await customerDataMicroserviceRequest('POST', userPublicationBaseUrl, signUrl, token, { filename, type });
        return response?.data;
    };

    const uploadFileToSignedUrl = async (signedUrl, file) => {
        await fetch(signedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
            },
        });
    };

    const uploadFileWithProgress = (selfSignedUrl, file) => new Promise((resolve, reject) => {
        xhr.open('PUT', selfSignedUrl, true);
        xhr.setRequestHeader('Content-Type', file.type);

        xhr.onloadstart = () => setUploading(true);
        xhr.onloadend = () => {
            if (xhr.status === 200) {
                resolve();
            } else {
                reject(new Error('File upload failed!'));
            }
            setUploading(false);
        };

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded / event.total) * 100);
                setUploadProgress(percentComplete);
            }
        };
        xhr.onerror = () => {
            reject(new Error('Network error!'));
        };
        xhr.onabort = () => {
            reject(new Error('Upload cancelled'));
        };
        xhr.send(file);
    });

    const uploadFiles = async (token) => {
        const pdfSignedUrl = await getSignedUrl(token, publicationFileName, 'document');
        const smTnSignedUrl = await getSignedUrl(token, smallThumbnailFile.name, 'thumbnail');
        const lgTnSignedUrl = await getSignedUrl(token, largeThumbnailFile.name, 'thumbnail');
        const highResolutionTnSignedUrl = await getSignedUrl(token, highResolutionThumbnailFile.name, 'thumbnail');
        if (!pdfSignedUrl || !smTnSignedUrl || !lgTnSignedUrl || !highResolutionThumbnailFile) {
            return false;
        }
        await uploadFileToSignedUrl(smTnSignedUrl.signed_url, smallThumbnailFile);
        await uploadFileToSignedUrl(lgTnSignedUrl.signed_url, largeThumbnailFile);
        await uploadFileToSignedUrl(highResolutionTnSignedUrl.signed_url, highResolutionThumbnailFile);
        await uploadFileWithProgress(pdfSignedUrl.signed_url, pdfFile);

        return {
            file_s3key: pdfSignedUrl.s3_key,
            thumbnail_s3key: smTnSignedUrl.s3_key,
            thumbnail_large_s3key: lgTnSignedUrl.s3_key,
            thumbnail_high_resolution_s3key: highResolutionTnSignedUrl.s3_key,
        };
    };

    const handleAddEdit = async () => {
        try {
            setSaving(true);
            setUploadProgress(0);
            const isEdit = actionType === EDIT;

            const token = await getAuthToken(getAccessTokenSilently);
            const method = isEdit ? 'PUT' : 'POST';
            let url = `/organizations/${salesforceId}/user-publication`;
            if (isEdit) {
                url = `${url}/${publicationId}`;
            }
            let dbPayload = {
                title: publicationTitle,
                type: 'user',
                publication_date: getDateTimeStr(publicationDateLabel),
                user_display_date: getDateTimeStr(userDisplayDate),
            };

            if (actionType === ADD || publicationFileName) {
                const imgRes = await uploadFiles(token);
                if (!imgRes) {
                    toast.error('There was an error uploading file');
                    setSaving(false);
                    return;
                }
                dbPayload = {
                    ...dbPayload,
                    ...imgRes,

                };
            }

            await customerDataMicroserviceRequest(method, userPublicationBaseUrl, url, token, dbPayload);

            toast.success(`Publication ${isEdit ? 'Edited' : 'Added'}`);
            loadPublications();
            setIsAddEditView(false);
            setIsDrawerOpen(false);
            setSaving(false);
            setManagePublicationsTitle('Manage Publications');
        } catch (err) {
            if (err.message === 'Upload cancelled') {
                toast.warn(err.message);
            } else {
                // eslint-disable-next-line no-console
                console.log(err);
                toast.error('There was an error uploading file');
            }
            setSaving(false);
        }
    };

    const infoElement = (label, title, id) => (
        <InputLabel htmlFor={id}>
            <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
            >
                {label}
                {' '}
                <i>Optional</i>
                {' '}
                <Tooltip
                    placement="right-end"
                    title={title}
                >
                    <FontAwesomeIcon icon={faCircleInfo} />
                </Tooltip>
            </Typography>
        </InputLabel>
    );

    return (
        <>
            {uploading && (
                <>
                    <div>Uploading...</div>
                    <progress id="uploadProgress" max="100" value={uploadProgress} />
                </>
            )}
            <Box mt={2}>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                >
                    File upload
                </Typography>
                <FileUploadContainer
                    types={['PDF']}
                    name="file"
                    maxSize={50}
                    fileName={publication?.name || publicationFileName}
                    fileUrl={largeThumbnailUrl}
                    handleChange={handleFileChanged}
                    onClear={handleClearFile}
                    required
                    disabled={isHidden}
                />
                {fileError && (
                    <p style={{ color: 'red' }}>{fileError}</p>
                )}
                {infoElement('Publication Date Label -', PUB_DATE_LABEL, 'publication-date-id')}
                {publicationDateInputField('publication-date-id')}
                <Box mt={2}>
                    {infoElement('Publication Title -', PUB_TITLE_LABEL, 'publicationDateInput')}
                    <FormControl variant="standard">
                        <OutlinedInput
                            id="publicationDateInput"
                            type="text"
                            value={publicationTitle}
                            onChange={(e) => setPublicationTitle(e.target.value)}
                            size="medium"
                            sx={{ minWidth: '205px' }}
                            disabled={isHidden}
                        />
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="div"
                    >
                        Schedule Publication
                    </Typography>
                    <Button
                        color={COLORS.lightgray}
                        size={SIZES.small}
                        onClick={() => setViewSchdPubDate(false)}
                        disabled={isHidden}
                    >
                        Display Now
                    </Button>
                    <Button
                        color={COLORS.flat}
                        size={SIZES.small}
                        outline
                        onClick={() => setViewSchdPubDate(true)}
                        disabled={isHidden}
                    >
                        Schedule
                    </Button>
                </Box>
                {viewSchdPubDate && (
                    <Box mt={4}>
                        <InputLabel htmlFor="user-display-date-id">
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                component="div"
                            >
                                Schedule Date
                            </Typography>
                        </InputLabel>
                        {userDisplayDateInputField('user-display-date-id')}
                    </Box>
                )}
                <Grid
                    container
                    rowSpacing={1}
                    mt={4}
                    columnSpacing={3}
                >
                    <Grid>
                        <Box>
                            <Button
                                color={COLORS.flat}
                                size={SIZES.medium}
                                onClick={handleAddEditCancel}
                                disabled={isHidden}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                    <Grid>
                        <Box>
                            <Button
                                size={SIZES.medium}
                                disabled={saving || (actionType === ADD ? !pdfFile : !publicationId) || isHidden}
                                onClick={handleAddEdit}
                            >
                                {actionType === EDIT ? 'Edit' : 'Add'}
                                {' '}
                                Publication
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

PublicationAddEdit.propTypes = {
    salesforceId: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    setIsDrawerOpen: PropTypes.func.isRequired,
    setIsAddEditView: PropTypes.func.isRequired,
    loadPublications: PropTypes.func.isRequired,
    publication: PropTypes.shape({
        publicationId: PropTypes.number,
        fileUrl: PropTypes.string,
        name: PropTypes.string,
        publishDate: PropTypes.string,
        displayDate: PropTypes.string,
        largeThumbnailUrl: PropTypes.string,
        smallThumbnailUrl: PropTypes.string,
        largeThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        smallThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        title: PropTypes.string,
        type: PropTypes.string,
    }),
    setManagePublicationsTitle: PropTypes.func,
    isHidden: PropTypes.bool,
};

PublicationAddEdit.defaultProps = {
    publication: null,
    setManagePublicationsTitle: () => {},
    isHidden: false,
};
